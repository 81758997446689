import {IStore} from "modules/types";
import {createSelector} from "reselect";
import {find, memoize} from "lodash";

const getClearSquads = ({squads}: IStore) => squads;
const getStats = ({shared}: IStore) => shared.teamsStats;

export const getAllSquads = createSelector(getClearSquads, (squads) =>
	squads.map((squad) => ({
		...squad,
	}))
);

export const getAllSquadsWithMergedStats = createSelector(
	getClearSquads,
	getStats,
	(squads, stats) => {
		return squads.map((squad) => ({...squad, stats: {...squad.stats, ...stats[squad.id]}}));
	}
);

export const getSquadById = createSelector(getAllSquads, (squads) =>
	memoize((id: number = 0) => find(squads, {id}))
);
