import {ILeagueReducer} from "modules/types/reducers";
import {createReducer} from "redux-act";
import {RequestStateType, Steps} from "modules/types/enums";
import {
	createLeagueClear,
	createLeagueClearStep,
	createLeagueRequest,
	createLeagueSuccess,
	fetchJoinLeaguesClear,
	fetchJoinLeaguesConcat,
	fetchJoinLeaguesRequest,
	fetchJoinLeaguesSuccess,
	fetchLeaguesRequest,
	fetchLeaguesSuccess,
	fetchLeagueTableConcat,
	fetchLeagueTableRequest,
	fetchLeagueTableSuccess,
	fetchLeagueUsersRequest,
	fetchLeagueUsersSuccess,
	joinLeagueClear,
	joinLeagueRequest,
	JoinLeagueSuccess,
	leagueRemoveUserClear,
	leagueRemoveUserRequest,
	leagueRemoveUserSuccess,
	leagueRequestGlobalStateHandler,
	leaguesClear,
	leagueUsersClear,
	leaveLeagueClear,
	leaveLeagueRequest,
	leaveLeagueSuccess,
	sendLeagueInviteClear,
	sendLeagueInviteRequest,
	sendLeagueInviteSuccess,
	setLeagueJoined,
	updateLeagueRequest,
	updateLeagueSuccess,
} from "modules/actions";
import {
	IGenerateRequestStateHandler,
	ILeague,
	ILeagueRankItem,
	ILeagueUser,
	IWithNextPagePayload,
	IWithNextPayload,
} from "modules/types";
import {IApiFetchJoinLeagueClearResponse} from "modules/types/api";

const defaultState: ILeagueReducer = {
	leagues: [],
	joinLeagues: {
		leagues: [],
		nextPage: false,
	},
	createActiveStep: Steps.First,
	leagueRanks: [],
	leagueUsers: [],
	leagueTableData: {
		ranks: [],
		next: false,
	},
	leagueUsersNewPage: false,
	isLeagueCreated: false,
	leagueRequests: {
		fetch: RequestStateType.Idle,
		create: RequestStateType.Idle,
		update: RequestStateType.Idle,
		joinFetch: RequestStateType.Idle,
		join: RequestStateType.Idle,
		leave: RequestStateType.Idle,
		sendInvites: RequestStateType.Idle,
		fetchLeagueRanks: RequestStateType.Idle,
		fetchLeagueUsers: RequestStateType.Idle,
		removeLeagueUser: RequestStateType.Idle,
		fetchLeagueTable: RequestStateType.Idle,
	},
};

const onfetchLeagueRequest = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, fetch: RequestStateType.Loading},
});

const onfetchLeagueSuccess = (state: ILeagueReducer, payload: ILeague[]): ILeagueReducer => ({
	...state,
	leagues: payload,
	leagueRequests: {...state.leagueRequests, fetch: RequestStateType.Success},
});

const onLeaguesClear = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagues: [],
});

const onCreateLeagueRequest = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, create: RequestStateType.Loading},
});

const onCreateLeagueSuccess = (state: ILeagueReducer, payload: ILeague[]): ILeagueReducer => ({
	...state,
	leagues: [...state.leagues, ...payload],
	isLeagueCreated: true,
	leagueRequests: {...state.leagueRequests, create: RequestStateType.Success},
});

const onCreateLeagueClear = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, create: RequestStateType.Idle},
});

const onCreateLeagueClearStep = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	isLeagueCreated: false,
});

const onFetchJoinLeaguesRequest = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, joinFetch: RequestStateType.Loading},
});

const onFetchJoinLeaguesSuccess = (
	state: ILeagueReducer,
	payload: IApiFetchJoinLeagueClearResponse
): ILeagueReducer => ({
	...state,
	joinLeagues: payload,
	leagueRequests: {...state.leagueRequests, joinFetch: RequestStateType.Success},
});

const onFetchJoinLeaguesConcat = (
	state: ILeagueReducer,
	payload: IApiFetchJoinLeagueClearResponse
): ILeagueReducer => ({
	...state,
	joinLeagues: {
		leagues: [...state.joinLeagues.leagues, ...payload.leagues],
		nextPage: payload.nextPage,
	},
	leagueRequests: {...state.leagueRequests, joinFetch: RequestStateType.Success},
});

const onFetchJoinLeaguesClear = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, joinFetch: RequestStateType.Idle},
});

const onJoinLeagueRequest = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, join: RequestStateType.Loading},
});

const onJoinLeagueSuccess = (state: ILeagueReducer, payload: ILeague): ILeagueReducer => ({
	...state,
	leagues: [...state.leagues, payload],
	leagueRequests: {...state.leagueRequests, join: RequestStateType.Success},
});

const onJoinLeagueClear = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, join: RequestStateType.Idle},
});

const onLeaveLeagueRequest = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, leave: RequestStateType.Loading},
});

const onLeaveLeagueSuccess = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, leave: RequestStateType.Success},
});

const onLeaveLeagueClear = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, leave: RequestStateType.Idle},
});

const onUpdateLeagueRequest = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, update: RequestStateType.Loading},
});

const onUpdateLeagueSuccess = (state: ILeagueReducer, payload: ILeague): ILeagueReducer => {
	const leagues = [...state.leagues];
	const leagueId = state.leagues.findIndex((league) => league.id === payload.id);
	if (leagueId !== -1) {
		leagues[leagueId] = payload;
	}

	return {
		...state,
		leagues: leagues,
		leagueRequests: {...state.leagueRequests, update: RequestStateType.Success},
	};
};

const onSetLeagueJoined = (state: ILeagueReducer, payload: number): ILeagueReducer => {
	const leagues = [...state.leagues];
	const leagueId = state.leagues.findIndex((league) => league.id === payload);
	if (leagueId !== -1) {
		leagues[leagueId] = {...leagues[leagueId], is_joined: true};
	}

	return {
		...state,
		leagues: leagues,
	};
};

const onSendLeagueInviteRequest = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, sendInvites: RequestStateType.Loading},
});

const onSendLeagueInviteSuccess = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, sendInvites: RequestStateType.Success},
});

const onSendLeagueInviteClear = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, sendInvites: RequestStateType.Idle},
});

const onFetchLeagueUsersRequest = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, fetchLeagueUsers: RequestStateType.Loading},
});

const onFetchLeagueUsersSuccess = (
	state: ILeagueReducer,
	request: IWithNextPagePayload<ILeagueUser[]>
): ILeagueReducer => ({
	...state,
	leagueUsers: request.payload,
	leagueUsersNewPage: request.newPage,
	leagueRequests: {...state.leagueRequests, fetchLeagueUsers: RequestStateType.Success},
});

const onLeagueUsersClear = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueUsers: [],
});

const onLeagueRemoveUserRequest = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, removeLeagueUser: RequestStateType.Loading},
});

const onLeagueRemoveUserSuccess = (state: ILeagueReducer, payload: number): ILeagueReducer => {
	const copy = [...state.leagueUsers];
	const index = state.leagueUsers.findIndex((user) => user.userId === payload);

	if (index !== -1) {
		copy.splice(index, 1);
	}

	return {
		...state,
		leagueUsers: copy,
		leagueRequests: {...state.leagueRequests, removeLeagueUser: RequestStateType.Success},
	};
};

const onLeagueRemoveUserClear = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, removeLeagueUser: RequestStateType.Loading},
});

const onFetchLeagueTableRequest = (state: ILeagueReducer): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, fetchLeagueTable: RequestStateType.Loading},
});

const onFetchLeagueTableSuccess = (
	state: ILeagueReducer,
	payload: IWithNextPayload<ILeagueRankItem[]>
): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, fetchLeagueTable: RequestStateType.Success},
	leagueTableData: {
		next: payload.next,
		ranks: payload.payload,
	},
});

const onFetchLeagueTableConcat = (
	state: ILeagueReducer,
	payload: IWithNextPayload<ILeagueRankItem[]>
): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, fetchLeagueTable: RequestStateType.Success},
	leagueTableData: {
		next: payload.next,
		ranks: [...state.leagueTableData.ranks, ...payload.payload],
	},
});

const onLeagueRequestGlobalStateHandler = (
	state: ILeagueReducer,
	payload: IGenerateRequestStateHandler<ILeagueReducer["leagueRequests"]>
): ILeagueReducer => ({
	...state,
	leagueRequests: {...state.leagueRequests, [payload.key]: payload.state},
});

export const leagues = createReducer<ILeagueReducer>({}, defaultState)
	.on(fetchLeaguesRequest, onfetchLeagueRequest)
	.on(fetchLeaguesSuccess, onfetchLeagueSuccess)
	.on(leaguesClear, onLeaguesClear)
	.on(setLeagueJoined, onSetLeagueJoined)

	.on(createLeagueRequest, onCreateLeagueRequest)
	.on(createLeagueSuccess, onCreateLeagueSuccess)
	.on(createLeagueClear, onCreateLeagueClear)
	.on(createLeagueClearStep, onCreateLeagueClearStep)

	.on(updateLeagueRequest, onUpdateLeagueRequest)
	.on(updateLeagueSuccess, onUpdateLeagueSuccess)

	.on(fetchJoinLeaguesRequest, onFetchJoinLeaguesRequest)
	.on(fetchJoinLeaguesSuccess, onFetchJoinLeaguesSuccess)
	.on(fetchJoinLeaguesConcat, onFetchJoinLeaguesConcat)
	.on(fetchJoinLeaguesClear, onFetchJoinLeaguesClear)

	.on(joinLeagueRequest, onJoinLeagueRequest)
	.on(JoinLeagueSuccess, onJoinLeagueSuccess)
	.on(joinLeagueClear, onJoinLeagueClear)

	.on(leaveLeagueRequest, onLeaveLeagueRequest)
	.on(leaveLeagueSuccess, onLeaveLeagueSuccess)
	.on(leaveLeagueClear, onLeaveLeagueClear)

	.on(sendLeagueInviteRequest, onSendLeagueInviteRequest)
	.on(sendLeagueInviteSuccess, onSendLeagueInviteSuccess)
	.on(sendLeagueInviteClear, onSendLeagueInviteClear)

	.on(fetchLeagueUsersRequest, onFetchLeagueUsersRequest)
	.on(fetchLeagueUsersSuccess, onFetchLeagueUsersSuccess)
	.on(leagueUsersClear, onLeagueUsersClear)

	.on(leagueRemoveUserRequest, onLeagueRemoveUserRequest)
	.on(leagueRemoveUserSuccess, onLeagueRemoveUserSuccess)
	.on(leagueRemoveUserClear, onLeagueRemoveUserClear)

	.on(fetchLeagueTableRequest, onFetchLeagueTableRequest)
	.on(fetchLeagueTableSuccess, onFetchLeagueTableSuccess)
	.on(fetchLeagueTableConcat, onFetchLeagueTableConcat)

	.on(leagueRequestGlobalStateHandler, onLeagueRequestGlobalStateHandler);
