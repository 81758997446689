import {createReducer} from "redux-act";
import {clearGlobalError, showGlobalError} from "modules/actions";
import {IError} from "modules/types";

interface IErrorReducer {
	text?: string;
	code?: number;
}

const defaultState: IErrorReducer = {};

export const error = createReducer<IErrorReducer>({}, defaultState)
	.on(showGlobalError, (state: IErrorReducer, {message, code}: IError) => ({
		text: message,
		code,
	}))
	.on(clearGlobalError, () => ({}));
