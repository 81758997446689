import {createAction} from "redux-act";
import {
	ICreateLeagueForm,
	ICreateLeagueRequestPayload,
	IFetchLeaguesGetParams,
	IFetchRanksRequestPayload,
	IGenerateRequestStateHandler,
	ILeague,
	ILeagueInvitePayload,
	IRankItem,
	ILeagueRankItem,
	ILeagueRemoveUserPayload,
	ILeagueUser,
	IPaginationRequest,
	ISportWithIdPayload,
	IWithIdPayload,
	IWithNextPagePayload,
	IWithNextPayload,
} from "modules/types";
import {IApiFetchJoinLeagueClearResponse} from "modules/types/api";
import {ILeagueReducer} from "modules/types/reducers";

export const fetchLeaguesRequest = createAction<IFetchLeaguesGetParams>();
export const fetchLeaguesSuccess = createAction<ILeague[]>();

export const leaguesClear = createAction();
export const setLeagueJoined = createAction<number>();

export const createLeagueRequest = createAction<ICreateLeagueForm>();
export const createLeagueSuccess = createAction<ILeague[]>();
export const createLeagueClear = createAction();
export const createLeagueClearStep = createAction();

export const updateLeagueRequest = createAction<ISportWithIdPayload<ICreateLeagueRequestPayload>>();
export const updateLeagueSuccess = createAction<ILeague>();
export const updateLeagueClear = createAction();

export const fetchJoinLeaguesRequest = createAction<IPaginationRequest>();
export const fetchJoinLeaguesSuccess = createAction<IApiFetchJoinLeagueClearResponse>();
export const fetchJoinLeaguesConcat = createAction<IApiFetchJoinLeagueClearResponse>();
export const fetchJoinLeaguesClear = createAction();

export const joinLeagueRequest = createAction<string>();
export const JoinLeagueSuccess = createAction<ILeague>();
export const joinLeagueClear = createAction();

export const leaveLeagueRequest = createAction<number>();
export const leaveLeagueSuccess = createAction();
export const leaveLeagueClear = createAction();

export const sendLeagueInviteRequest = createAction<IWithIdPayload<ILeagueInvitePayload[]>>();
export const sendLeagueInviteSuccess = createAction();
export const sendLeagueInviteClear = createAction();

export const fetchLeagueUsersRequest = createAction<IWithIdPayload<IPaginationRequest>>();
export const fetchLeagueUsersSuccess = createAction<IWithNextPagePayload<ILeagueUser[]>>();
export const leagueUsersClear = createAction();

export const fetchLeagueRanksRequest = createAction<IWithIdPayload<IFetchRanksRequestPayload>>();
export const fetchLeagueRanksSuccess = createAction<IRankItem[]>();

export const leagueRemoveUserRequest = createAction<ILeagueRemoveUserPayload>();
export const leagueRemoveUserSuccess = createAction<number>();
export const leagueRemoveUserClear = createAction();

export const fetchLeagueTableRequest = createAction<IWithIdPayload<IFetchRanksRequestPayload>>();
export const fetchLeagueTableSuccess = createAction<IWithNextPayload<ILeagueRankItem[]>>();
export const fetchLeagueTableConcat = createAction<IWithNextPayload<ILeagueRankItem[]>>();

export const leagueRequestGlobalStateHandler =
	createAction<IGenerateRequestStateHandler<ILeagueReducer["leagueRequests"]>>();
