import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg``;

export const IconPlus: React.FC<IProps> = ({color, width, height, ...rest}) => (
	<SVG width={width} height={height} viewBox="0 0 30 30" fill="none" {...rest}>
		<g transform="translate(-356.000000, -844.000000)">
			<g transform="translate(356.000000, 844.000000)" fill={color}>
				<polygon
					id="Path"
					points="16.8 24 13.2 24 13.2 16.8 6 16.8 6 13.2 13.2 13.2 13.2 6 16.8 6 16.8 13.2 24 13.2 24 16.8 16.8 16.8"
				/>
			</g>
		</g>
	</SVG>
);

IconPlus.defaultProps = {
	width: 12,
	height: 12,
	color: "currentColor",
};
