import {IStore} from "modules/types";
import {createSelector} from "reselect";
import {find, last, memoize} from "lodash";
import {ILeagueReducer} from "modules/types/reducers";
import {RequestStateType} from "modules/types/enums";

const getState = ({leagues}: IStore) => leagues;
const getUser = ({user}: IStore) => user.user;

export const getLeagues = createSelector(getState, (state) => state.leagues);
export const getLastCreatedLeague = createSelector(getLeagues, (leagues) => last(leagues));
export const getIsLeagueOwner = createSelector(getLeagues, getUser, (leagues, user) => {
	return memoize((id: number = 0) => {
		const league = leagues.find((item) => item.id === id);
		if (!league) {
			return false;
		}
		return league.user_id === user?.id;
	});
});
export const getLeagueById = createSelector(getLeagues, (leagues) =>
	memoize((id: number = 0) => find(leagues, {id}))
);

export const getLeagueRequestStateByKey = createSelector(getState, (state) => {
	return memoize((key: keyof ILeagueReducer["leagueRequests"]) => {
		return {
			isIdle: state.leagueRequests[key] === RequestStateType.Idle,
			isLoading: state.leagueRequests[key] === RequestStateType.Loading,
			isSuccess: state.leagueRequests[key] === RequestStateType.Success,
			isError: state.leagueRequests[key] === RequestStateType.Error,
		};
	});
});

export const getIsLeagueCreated = createSelector(getState, (state) => state.isLeagueCreated);

export const getJoinLeaguesState = createSelector(getState, (state) => state.joinLeagues);

export const getLeagueUsers = createSelector(getState, (state) => ({
	users: state.leagueUsers,
	nextPage: state.leagueUsersNewPage,
}));

export const getLeagueTableData = createSelector(getState, (state) => state.leagueTableData);
