import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg``;

export const IconRollback: React.FC<IProps> = ({width = 13, height = 14, color = "#222222"}) => {
	return (
		<React.Fragment>
			<SVG
				width={width}
				height={height}
				viewBox="0 0 13 14"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0.433594 6.50079V7.25079H1.93359V6.50079C1.93359 4.01904 3.95184 2.00079 6.43359 2.00079C8.91534 2.00079 10.9336 4.01904 10.9336 6.50079C10.9336 8.98254 8.91534 11.0008 6.43359 11.0008H5.68359V9.55699L2.92351 11.7508L5.68359 13.9446V12.5008H6.43359C9.74184 12.5008 12.4336 9.80904 12.4336 6.50079C12.4336 3.19254 9.74184 0.500793 6.43359 0.500793C3.12534 0.500793 0.433594 3.19254 0.433594 6.50079Z"
					fill={color}
				/>
			</SVG>
		</React.Fragment>
	);
};
