import {createAction} from "redux-act";
import {IPlayer, IPlayerStat} from "modules/types/json";
import {IStatsCentrePlayer} from "modules/types/shared";

export const fetchPlayers = createAction();
export const fetchPlayersSuccess = createAction<IPlayer[]>();

export const fetchPlayerStatsRequest = createAction<number>();
export const fetchPlayerStatsSuccess = createAction<IPlayerStat[]>();
export const playerStatsClear = createAction();

export const fetchAllPlayersStatsRequest = createAction();
export const fetchAllPlayersStatsSuccess = createAction<Record<number, IStatsCentrePlayer>>();
