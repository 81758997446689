import {createAction} from "redux-act";
import {IDictionary} from "modules/types";
import {IStatsCentreTeam} from "modules/types/shared";

export interface ISquadStats {
	goals: number;
	own_goals: number;
	goals_conceded: number;
	played: number;
	assists: number;
	star_man: number;
	saved_pen: number;
	missed_pen: number;
	round_scores: IDictionary<number>;
	total_points: number;
	red_cards: number;
	yellow_cards: number;
}

export interface ISquad {
	id: number;
	sport: string;
	competitions?: string[];
	name: string;
	short_name: string;
	stats: ISquadStats | null;
}

export interface ISquadWithStats {
	id: ISquad["id"];
	sport: ISquad["sport"];
	competitions?: ISquad["competitions"];
	name: ISquad["name"];
	short_name: ISquad["short_name"];
	stats: IStatsCentreTeam;
}

export const fetchSquads = createAction();
export const fetchSquadsSuccess = createAction<ISquad[]>();
export const fetchSquadsFailed = createAction<Error>();
