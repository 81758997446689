import {createReducer} from "redux-act";
import {ISquad} from "modules/types";
import {fetchSquadsSuccess} from "modules/actions";

export type ISquadsReducer = ISquad[];

const onFetchSquadsSuccess = (state: ISquadsReducer, payload: ISquad[]) => payload;

export const squads = createReducer<ISquadsReducer>({}, []).on(
	fetchSquadsSuccess,
	onFetchSquadsSuccess
);
