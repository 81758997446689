import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getNotificationState} from "modules/selectors";
import styled from "styled-components";
import {hideNotification} from "modules/actions";
import {IconChecked, IconInfo, IconRemove} from "components/Icons";
import {PureButton} from "components/styled";

const Wrapper = styled.div`
	box-sizing: border-box;
	position: absolute;
	bottom: 0;
	transform: translateY(100%);
	width: 100%;
	padding: 10px 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;

	background: #0bad0b;

	.action-icon {
		display: none;
	}

	&.success {
		background: #0bad0b;
		.action-icon.success {
			display: block !important;
		}
	}

	&.danger {
		background: #f02f42;
		.action-icon.danger {
			display: block !important;
		}
	}

	&.warning {
		// MUI palette.warning.main
		background: #ffa726;
		.action-icon.warning {
			display: block !important;
		}
	}

	span {
		color: #ffffff;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
	}
`;

const CloseButton = styled(PureButton)`
	position: absolute;
	right: 14px;
	top: 50%;
	transform: translateY(-50%);
`;

export const Notification: React.FC = () => {
	const dispatch = useDispatch();
	const notification = useSelector(getNotificationState);

	const closeNotification = useCallback(() => {
		dispatch(hideNotification());
	}, [dispatch]);

	useEffect(() => {
		if (notification) {
			setTimeout(() => {
				dispatch(hideNotification());
			}, notification.duration || 0);
		}
	}, [dispatch, notification]);

	if (!notification) {
		return null;
	}

	return (
		<Wrapper className={notification.type}>
			<div className="action-icon success">
				<IconChecked color="#FFFFFF" width={18} height={18} />
			</div>
			<div className="action-icon warning">
				<IconInfo color="#FFFFFF" width={14} height={14} />
			</div>

			<span>{notification.message || "Some notification"}</span>

			<CloseButton onClick={closeNotification}>
				<IconRemove color="#FFFFFF" width={14} height={14} />
			</CloseButton>
		</Wrapper>
	);
};
