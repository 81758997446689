import React from "react";
import {Navigate, Outlet, RouteProps, useLocation} from "react-router-dom";
import {getIsUserLogged} from "modules/selectors";
import {useSelector} from "react-redux";

export const PrivateRoute: React.FC<RouteProps> = (props) => {
	const location = useLocation();
	const isLoggedIn = useSelector(getIsUserLogged);

	if (isLoggedIn) {
		return <Outlet />;
	}
	return <Navigate state={{from: location.pathname}} to="/login" />;
};
