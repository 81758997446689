export const media = {
	sm: "600px",
	md: "900px",
	lg: "1200px",
	xl: "1536px",

	largeThanSM: "(min-width: 600px)",
	largeThanMD: "(min-width: 900px)",
	largeThanLG: "(min-width: 1200px)",
	largeThanXL: "(min-width: 1536px)",

	lessThanSM: "(max-width: 600px)",
	lessThanMD: "(max-width: 900px)",
	lessThanLG: "(max-width: 1200px)",
	lessThanXL: "(max-width: 1536px)",
};
