import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	colorTop?: string;
	colorBottom?: string;
	onClick?: () => void;
}

const SVG = styled.svg``;

export const IconTransfer: React.FC<IProps> = ({colorTop, colorBottom, ...rest}) => (
	<SVG viewBox="0 0 24 24" fill="none" {...rest}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.8779 3.74319C20.0531 3.57611 20.322 3.55522 20.5206 3.68053L20.601 3.74319L23.8506 6.85425C24.0249 7.02133 24.0467 7.27922 23.916 7.469L23.8506 7.5457L20.601 10.6568C20.4008 10.8477 20.0781 10.8477 19.8779 10.6568C19.7037 10.4898 19.6819 10.2319 19.8126 10.0421L19.8779 9.9654L22.2564 7.68837L0 7.68884V6.71111L22.2552 6.71038L19.8779 4.43455C19.7037 4.26746 19.6819 4.00958 19.8126 3.81986L19.8779 3.74319Z"
			fill={colorTop}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.23717 13.3432C3.42779 13.1522 3.74992 13.1522 3.95787 13.3432C4.13983 13.5102 4.17273 13.7681 4.05108 13.9578L3.98948 14.0345L1.7184 16.3104L23.9562 16.3111L24 17.2888L1.7616 17.2884L4.23922 19.5654C4.42118 19.7324 4.45486 19.9903 4.33273 20.1801L4.27083 20.2568C4.10403 20.4238 3.83655 20.4447 3.63298 20.3194L3.55012 20.2568L0.165762 17.1456C-0.0161978 16.9785 -0.0498693 16.7207 0.0722598 16.531L0.134162 16.4543L3.23717 13.3432Z"
			fill={colorBottom}
		/>
	</SVG>
);

export const IconTransferBold: React.FC = () => (
	<SVG viewBox="0 0 24 24" width="24" height="24" fill="none">
		<path
			d="M17.9739901,1.2 L23.973,7.199 L23.9744588,7.19953138 L17.9739901,13.2 L15.78,11.0060099 L18.086,8.7 L-5.75539616e-14,8.7 L-5.75539616e-14,5.7 L18.086,5.7 L15.78,3.39399014 L17.9739901,1.2 Z"
			id="Combined-Shape"
			fill="#DE0000"
		/>
		<path
			d="M17.9739901,10.8 L23.973,16.799 L23.9744588,16.7995314 L17.9739901,22.8 L15.78,20.6060099 L18.086,18.3 L-5.75539616e-14,18.3 L-5.75539616e-14,15.3 L18.086,15.3 L15.78,12.9939901 L17.9739901,10.8 Z"
			id="Combined-Shape-Copy-2"
			fill="#079E65"
			transform="translate(11.987229, 16.800000) scale(-1, 1) translate(-11.987229, -16.800000) "
		/>
	</SVG>
);

IconTransfer.defaultProps = {
	width: 24,
	height: 24,
	colorTop: "#E90052",
	colorBottom: "#04777B",
};
