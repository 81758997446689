import React from "react";
import {NonLoggedHeaderBlock} from "components/Header/NonLoggedHeaderBlock";
import {DesktopMenu} from "components/Header/DesktopMenu";
import {MobileMenu} from "components/Header/MobileMenu";
import {useMediaQuery} from "@mui/material";
import {Notification} from "components";
import styled from "styled-components";

const Wrapper = styled.div`
	position: relative;
`;

export const AppHeader: React.FC = () => {
	const isMobile = useMediaQuery("(max-width: 1000px)");
	return (
		<Wrapper>
			{isMobile ? <MobileMenu /> : <DesktopMenu />}
			<Notification />
			<NonLoggedHeaderBlock />
		</Wrapper>
	);
};
