import {createReducer} from "redux-act";
import {IRoundReducer} from "modules/types/reducers";
import {fetchRoundsSuccess, selectRound} from "modules/actions";

const defaultState: IRoundReducer = {
	rounds: [],
};

export const round = createReducer({}, defaultState)
	.on(fetchRoundsSuccess, (state, payload) => ({
		...state,
		rounds: payload,
	}))
	.on(selectRound, (state, payload) => ({
		...state,
		selectedRound: payload,
	}));
