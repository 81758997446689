import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg``;

export const IconChecked: React.FC<IProps> = ({width = 20, height = 20, color}) => {
	return (
		<SVG
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.50005 14.95L4.46505 13.8923L3.70005 13.15L2.80005 13.9909L5.50005 16.75L17.65 4.60001L16.75 3.70001L5.50005 14.95Z"
				fill={color}
			/>
		</SVG>
	);
};
